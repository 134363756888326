import React, { useContext, useState } from 'react';
import { Select, Button, message, Input, Card } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import './LiveMeeting.css';
import { generateLiveDemo } from 'Services/demoService';
import DemoContext from 'context/DemoContext';
import {
  BUTTON_TEXT,
  CONTENT_TEXT,
  ERROR_MESSAGES,
  SUCCESS_MESSAGES,
  WARNING_MESSAGES,
} from './constants';

const { Option } = Select;

/**
 * This component renders the live meeting generation interface for hosts
 *
 * @param {Object} props The component props
 * @param {Array} props.products Array of products available for live demo
 * @return {ReactNode} A React component that renders the live meeting generation interface
 */
const LiveMeeting = ({ products }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [meetingUrl, setMeetingUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const { userDemos, userAcceptedDemos, hostUserOtherAccepted } =
    useContext(DemoContext);

  /**
   * Handles the generation of live meeting URL
   *
   * @return {Promise<void>} A promise that resolves when the URL is generated
   */
  const handleGenerateUrl = async () => {
    if (!selectedProduct) {
      message.warning(WARNING_MESSAGES.SELECT_PRODUCT_WARNING);
      return;
    }

    setLoading(true);
    try {
      // Find the selected product details from products array
      const selectedProductDetails = products.find(
        (item) => item.id === selectedProduct
      );
      const storeId = selectedProductDetails?.store_details?.store_id;
      const productId = selectedProductDetails?.product?.identifier;
      const currentUtcDate = new Date().toISOString().split('T')[0];
      const currentUtcTime = new Date()
        .toISOString()
        .split('T')[1]
        .split('.')[0];

      const payload = {
        store_id: storeId,
        product_id: productId,
        date_time_range: [
          {
            date: currentUtcDate,
          },
        ],
        soonest_at: currentUtcDate,
        schedule: {
          scheduled_date: currentUtcDate,
          scheduled_time: currentUtcTime,
        },
      };
      const response = await generateLiveDemo(payload);
      setMeetingUrl(
        `${process.env.REACT_APP_URL}/live-meeting/${response?.data?.data?.id}`
      );

      userAcceptedDemos();
      userDemos();
      hostUserOtherAccepted('?order=true&offset=0');
      setSelectedProduct(null);

      message.success(SUCCESS_MESSAGES.GENERATE_URL_SUCCESS);
    } catch (error) {
      message.error(
        error?.response?.data?.message || ERROR_MESSAGES.GENERATE_URL_ERROR
      );
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handles copying the meeting URL to clipboard
   *
   * @return {void}
   */
  const handleCopy = () => {
    navigator.clipboard.writeText(meetingUrl);
    message.success(SUCCESS_MESSAGES.COPY_TO_CLIPBOARD);
  };

  return (
    <div className="live-meeting-container">
      <div className="dropdown-container">
        <div className="live-demo-input">
          <h4>Live Demo</h4>
          <div className="live-meeting-content">
            <div className="live-meeting-controls">
              <Select
                className="live-meeting-select"
                placeholder="Select a product"
                onChange={setSelectedProduct}
                value={selectedProduct}
                dropdownRender={(menu) => (
                  <div
                    className="live-meeting-dropdown-menu"
                    onMouseDown={(e) => e.stopPropagation()}
                  >
                    {menu}
                  </div>
                )}
              >
                {products.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.product?.name} ({item.store_details?.store_name})
                  </Option>
                ))}
              </Select>

              <Button
                type="primary"
                onClick={handleGenerateUrl}
                loading={loading}
                block
              >
                {BUTTON_TEXT.GENERATE_URL}
              </Button>
            </div>
          </div>
        </div>
        {meetingUrl && (
          <Card className="live-meeting-card">
            <p>
              {SUCCESS_MESSAGES.GENERATE_URL_SUCCESS}. {CONTENT_TEXT.EXPIRES_IN}
              <br />
              {CONTENT_TEXT.SHARE_WITH_GUEST}
            </p>
            <Input
              value={meetingUrl}
              readOnly
              addonAfter={<CopyOutlined onClick={handleCopy} />}
            />
          </Card>
        )}
      </div>
    </div>
  );
};

export default LiveMeeting;
