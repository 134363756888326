import React, { useCallback, useContext, useState } from "react";
import DemoContext from "context/DemoContext";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import { useEffectOnce } from "hooks/useEffectOnce";
import {
  Button,
  Container,
  Modal,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import RatingForm from "./RatingForm";
import "./publicprofile.css";
import { Icon } from "assets/images";
import DataContext from "context/DataContext";
import { useNavigate } from "react-router-dom";
import { base64Decode, base64Encode } from "@firebase/util";
import AuthContext from "context/AuthContext";
import ApiContext from "context/ApiContext";
import { generateSignature } from "utils/dataSecurity";

const SoftReview = () => {
  const { userExistCheck } = useContext(AuthContext);
  const { getPublicShopifyReviewProducts } = useContext(DemoContext);
  const { storeCommissions } = useContext(ApiContext);
  const { softReviews } = useContext(DataContext);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(true);
  const [showA, setShowA] = useState(false);
  const [showB, setShowB] = useState(false);
  const [existsUser, setExistsUser] = useState(null);
  const [productDataReview, setProductDataReview] = useState(null);
  const [alreadyReview, setAlreadyReview] = useState(null);
  const queryParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const [demoCommission, setdemoCommission] = useState(null);

  const allReviews = async () => {
    try {
      const res = await getPublicShopifyReviewProducts({
        customer_emails: [base64Decode(queryParams.get("invite"))],
        shop_id: queryParams.get("shop"),
      });
      // setLoading(false);
      if (res) setProductDataReview(res.data.data);

      return res;
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const getStoreId =
    productDataReview &&
    productDataReview.map((res) => {
      return res?.shop_id;
    });

  const accessDemoCommission = async () => {
    try {
      const res = await storeCommissions(
        `?signature=${base64Encode(
          generateSignature(`Hey, this is my customer id: ${getStoreId[0]}`)
        )}&store_id=${getStoreId[0]}`
      );
      if (res) return setdemoCommission(res.data.data[0]);
      return res;
    } catch (error) {
      throw error;
    }
  };

  const doneReviews = async () => {
    try {
      const res = await softReviews(
        null,
        `?order=true&store_id=${queryParams.get("shop")}&email=${base64Decode(
          queryParams.get("invite")
        )}`,
        3
      );

      if (res) setAlreadyReview(res.data.data.soft_review.results);
      return res;
    } catch (error) {
      throw error;
    }
  };

  const existingUser = async () => {
    try {
      const res = await userExistCheck({
        host_email: base64Decode(queryParams.get("invite")),
      });
      setExistsUser(res.data.data.exists);
      return res;
    } catch (error) {
      throw error;
    }
  };

  const getData = useCallback(() => {
    if (productDataReview && alreadyReview) {
      // let newReviewData = productDataReview.filter((p) => {
      //   return alreadyReview.some(
      //     (o) => o.soft_product.identfier !== p.product_id
      //   );
      // });
      // let newReviewData = productDataReview.filter(
      //   (p) =>
      //     !alreadyReview.find((o) => o.soft_product.identifier === p.product_id)
      // );
      const newArray = [];
      for (let i = 0; i < productDataReview.length; i++) {
        let found = false;
        for (let j = 0; j < alreadyReview.length; j++) {
          if (
            productDataReview[i].product_id ===
            alreadyReview[j].soft_product.identifier
          ) {
            found = true;
            newArray.push({
              ...productDataReview[i],
              stars: alreadyReview[j].stars,
              comment: alreadyReview[j].comment,
              reviewId: alreadyReview[j].id,
            });
            break;
          }
        }
        if (!found) {
          newArray.push(productDataReview[i]);
        }
      }
      // console.log(newArray);
      // let myData = showrooms.filter(
      //   (x) => x.product.identifier === alreadyReview[i].product_id
      // );
      // if (myData.length > 0) {
      //   setError(
      //     "Product already reviewed, please select another product for review now."
      //   );
      //   setActiveProduct(productList[i]);
      //   setIsEnabled(false);
      // } else {
      //   setActiveProduct(productList[i]);
      //   setError("");
      //   setIsEnabled(true);
      // }
      if (newArray.length < 1) {
        return (
          <div className="card cardc w-auto p-5 h6">No products to review</div>
        );
      }
      return newArray.map((product, i) => (
        <RatingForm
          key={i}
          detPro={product}
          newClassCustom="image-zoom-custom"
          allReviews={allReviews}
          doneReviews={doneReviews}
          setShowA={setShowA}
          setShowB={setShowB}
          userStatus={existsUser}
          accessDemoCommission={accessDemoCommission}
        />
      ));
    } else {
      return <></>;
    }
  }, [productDataReview, alreadyReview]);

  useEffectOnce(() => {
    if (queryParams.get("invite")) {
      const first = allReviews();
      const second = doneReviews();
      const third = existingUser();
      const promise = Promise.all([first, second, third]);
      promise
        .then((res) => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, []);

  if (loading) {
    return <PurebrandLoader />;
  } else {
    return (
      <>
        {/* <ToastContainer className="p-3" position="top-end"> </ToastContainer>*/}
        <Container>
          <div className="product-review-body-card">
            <h1>Welcome to Purebrand</h1>
            <p>
              You can see a list of your purchases. Take a moment to review
              them.
            </p>
            <Toast
              onClose={() => setShow(false)}
              show={show}
              delay={10000}
              autohide={false}
              animation
            >
              <Toast.Header>
                <img
                  src={Icon}
                  style={{ height: "20px", width: "20px" }}
                  className="rounded me-2"
                  alt=""
                />
                <strong className="me-auto">Signup and Earn</strong>
              </Toast.Header>
              <Toast.Body>
                Earn 💲 by demoing your products to other customers!
                <span
                  style={{ cursor: "pointer", fontWeight: "600" }}
                  onClick={() => navigate("/signup")}
                >
                  &nbsp; Learn More
                </span>
              </Toast.Body>
            </Toast>
          </div>
          <div className="showroom-container mt-3">
            <div className="col row">{getData()}</div>
          </div>
          <ToastContainer className="p-3" position={"top-end"}>
            <Toast
              show={showB}
              onClose={() => setShowB(false)}
              delay={3000}
              autohide={true}
            >
              <Toast.Header closeButton={false}>
                <img
                  src={Icon}
                  style={{ height: "20px", width: "20px" }}
                  className="rounded me-2"
                  alt=""
                />
                <strong className="me-auto">Cheers!!</strong>
              </Toast.Header>
              <Toast.Body>Your review has been saved!</Toast.Body>
            </Toast>
          </ToastContainer>
          <Modal show={showA} onHide={() => setShowA(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Review Submitted!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>
                  Congratulations, You have qualified to post a review and earn
                  <span>
                    &nbsp;
                    {demoCommission?.demo_commission}
                    {demoCommission?.demo_commission_type === "AMOUNT"
                      ? "💲"
                      : "%"}
                  </span>
                  . Opt-in to quickly sign up and start hosting through
                  Purebrand.
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex">
                <Button
                  onClick={() =>
                    navigate(
                      `/join?reference=${base64Encode(
                        JSON.stringify({
                          email: base64Decode(queryParams.get("invite")),
                          dataReview: alreadyReview,
                        })
                      )}`
                    )
                  }
                >
                  Get Started
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </Container>
      </>
    );
  }
};

export default SoftReview;
