export const GUEST_FORM_VALIDATION_ERRORS = {
  FULL_NAME: 'Please enter your name!',
  PHONE_NUMBER: 'Please enter your phone number!',
  EMAIL: 'Please enter your email!',
  MESSAGE_OPT_IN: 'Please opt-in to receive text messages!',
  PRODUCT_CATEGORY: 'Please select a product category!',
  VALID_EMAIL: 'Please enter a valid email!',
};

export const GUEST_FORM_LABELS = {
  FULL_NAME: 'Full Name',
  PHONE_NUMBER: 'Phone Number',
  EMAIL: 'Email',
  MESSAGE_OPT_IN: 'Message Opt-In',
  PRODUCT_CATEGORY: 'Product Category',
};

export const GUEST_FORM_PLACEHOLDERS = {
  FULL_NAME: 'Enter your name',
  PHONE_NUMBER: 'Enter your phone number',
  EMAIL: 'Enter your email',
  MESSAGE_OPT_IN: 'Enter your message opt-in',
  PRODUCT_CATEGORY: 'Select a product category',
};

export const LIVE_MEETING_EXPIRY_TEXT = 'Live meeting has expired';

export const CHECKBOX_TEXT =
  'I agree to receive text messages at the phone number provided.';
