export const ERROR_MESSAGES = {
  GENERATE_URL_ERROR: 'Failed to generate live meeting URL',
};

export const SUCCESS_MESSAGES = {
  GENERATE_URL_SUCCESS: 'Live meeting URL has been generated successfully',
  COPY_TO_CLIPBOARD: 'Copied to clipboard',
};

export const WARNING_MESSAGES = {
  SELECT_PRODUCT_WARNING: 'Please select a product',
};

export const BUTTON_TEXT = {
  GENERATE_URL: 'Generate Meeting URL',
};

export const CONTENT_TEXT = {
  EXPIRES_IN: 'This link expires after 60 minutes.',
  SHARE_WITH_GUEST:
    'Share this with the guest. Launch the meeting from your calendar below.',
};
