import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";

import "./recordMeetings.css";
import { useEffectOnce } from "../../hooks/useEffectOnce";
import VideoPlayerModal from "../Modal/VideoPlayerModal";
import { BlankImg, Icon } from "assets/images";

const RecordingCard = ({ recording }) => {
  const [dayDifference, setDayDifference] = useState(0);
  const [showVideoModal, setShowVideoModal] = useState(false);
  // const [recordingDetails, setRecordingDetails] = useState({});
  const handleClose = () => setShowVideoModal(false);
  const handleShow = () => setShowVideoModal(true);
  const [videoLink, setVideoLink] = useState();

  useEffect(() => {
    const currentDate = new Date();
    // const date = new Date(recording.ended_at);
    const date = new Date(recording.created_at);
    const day = Math.round((currentDate - date) / (1000 * 60 * 60 * 24));
    setDayDifference(day);
    // console.log(recording.recorded_video.recorded_video)
  }, [recording]);

  const videoUrl = () => {
    let videoResponse = fetch(recording?.recorded_video?.cloudinary_file);
    videoResponse
      .then(function (response) {
        if (response && response.status === 200) {
          setVideoLink(response.url);
        } else {
          setVideoLink(recording?.recorded_video?.recorded_video);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffectOnce(() => {
    videoUrl();
  }, []);

  return (
    <>
      <Card
        className="recording-card col-6 col-md-6 col-lg-4 col-xl-3"
        onClick={handleShow}
      >
        <div className="product-wrap">
          <div
            className="position-relative img-thumbnail-container"
            style={{ borderRadius: "10px", height: "250px" }}
          >
            <img
              src={recording.product ? recording.product.image : Icon}
              alt="thumbnail for the recording"
            />
            {/*<span className="video-duration position-absolute">5:19</span>*/}
          </div>
          <div className="d-flex align-items-center gap-2 mt-4 mb-2">
            <img
              src={
                recording.members.host_detail.profile_picture.file
                  ? recording.members.host_detail.profile_picture.file
                  : BlankImg
              }
              alt="profile"
              className="photo-profile"
            />
            <p className="m-0">
              {recording?.members?.host_detail?.first_name +
                " " +
                recording?.members?.host_detail?.last_name?.charAt(0) +
                "."}
            </p>
          </div>
          <div>
            <p className="mb-1">
              {recording.product && recording.product.name}
            </p>
            <span style={{ fontSize: "14px" }}>
              {dayDifference !== 0 ? dayDifference : "Today"}{" "}
              {dayDifference !== 0 ? "Days Ago" : ""}
            </span>
          </div>
        </div>
      </Card>
      <VideoPlayerModal
        show={showVideoModal}
        handleClose={handleClose}
        recordingUrl={
          recording?.recorded_video?.cloudinary_file === null &&
          recording?.recorded_video?.recorded_video === null
            ? null
            : videoLink
        }
        recordingName={recording?.recorded_video?.recording_name}
      />
    </>
  );
};

export default RecordingCard;
