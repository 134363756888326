import React from 'react';
import DemoContext from 'context/DemoContext';
import { Field, Form, Formik } from 'formik';
import { useContext } from 'react';
import { useState } from 'react';
import { Select, message } from 'antd';
import moment from 'moment-timezone';
import './mymeeting.css';
import {
  calculateDifferenceToday,
  convertTimeZoneFormat,
  convertToUTC,
  extractTimeOptions,
  getTimeArray,
  getTimeDifferencetimezone,
} from 'utils/dateFormatter';
import AuthContext from 'context/AuthContext';
import { useNavigate } from 'react-router-dom';
import DotsContext from 'context/DotsContext';
import { TEST_IDS } from './testIds';
import { GENERIC_ERROR_MESSAGE } from 'utils/constants';

/**
 * This component renders all the guest demo requests to the hosts.
 *
 * @param {props} props containing the demo information
 * @return {ReactNode} A React element that renders all the guest demo requests to the hosts.
 */

const MeetingReqView = (props) => {
  const [messageApi, contextHolder] = message.useMessage();

  const { user } = useContext(AuthContext);
  const [selectedDateTime, setSelectedDateTime] = useState('');
  const [selectedHour, setSelectedHour] = useState(false);
  const [selectedMin, setSelectedMin] = useState(false);
  const {
    userAcceptDemoRequest,
    userAcceptedDemos,
    hostUserOtherAccepted,
    userDemos,
    userRejectDemoRequest,
  } = useContext(DemoContext);

  const { storeList, salesStoreList } = useContext(DotsContext);

  const [enabled, setEnabled] = useState(true);
  const [newTiming, setNewTiming] = useState([]);
  const [productDropdownSelect, setProductDropdownSelect] = useState(null);
  const [productDropdownSelected, setProductDropdownSelected] = useState('');
  const navigate = useNavigate();

  /**
   * This function is used to check the disability of the host for accepting a demo
   *
   * @return {Boolean} true or false based on the required condition
   */

  const checkDemoDisabled = () => {

    const salesStoreIndex = salesStoreList.findIndex(
      (store) => store?.store?.store_id === props?.demoDetail?.store?.identifier
    );

    if (salesStoreIndex >= 0) {
      return false;
    }

    if (!storeList.length || props?.storesLoading) {
      return true;
    }

    const storeIndex = storeList.findIndex(
      (store) =>
        store?.product?.store?.identifier ===
          props?.demoDetail?.store?.identifier &&
        store?.store_payment_type?.dots?.is_verified &&
        store?.store_payment_type?.dots?.has_completed_onboarding === 'true'
    );

    if (storeIndex >= 0) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * This function is called when the demo request is accepted by a host
   *
   * @param {object} formValues values containing the scheduled date and time
   * @return {void}
   */

  const handleSubmit = (values) => {
    let utcDate, utcTime;
    setEnabled(false);
    if (
      props?.demoDetail?.date_time_range?.length > 0 &&
      (!props?.demoDetail?.date_time_range[0].time_options ||
        !props?.demoDetail?.date_time_range[0].time_options?.length)
    ) {
      [utcDate, utcTime] = convertToUTC(
        `${values?.demo_scheduled_date}T${values?.demo_scheduled_time}`
      );
    } else {
      [utcDate, utcTime] = convertToUTC(`
        ${moment(values?.demo_scheduled_date, 'YYYY-MM-DD , hh:mm A').format(
          'YYYY-MM-DD'
        )}T${moment(values?.demo_scheduled_date, 'YYYY-MM-DD , hh:mm A').format(
        'HH:mm'
      )}
      `);
    }

    const body = {
      schedule: {
        scheduled_date: utcDate,
        scheduled_time: utcTime,
      },
      product_id:
        props?.demoDetail?.product !== null
          ? props?.demoDetail.product.id
          : productDropdownSelect,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    const response = userAcceptDemoRequest(body, props.demoDetail.id);
    response
      .then(function (response) {
        if (response.status === 200) {
          userDemos();
          userAcceptedDemos();
          setSelectedDateTime('');
          setSelectedMin(false);
          setSelectedHour(false);
          setNewTiming([]);
          setEnabled(true);
          hostUserOtherAccepted('?order=true&offset=0');
        }
      })
      .catch(function (error) {
        userDemos();
        setSelectedDateTime('');
        userAcceptedDemos();
        setEnabled(true);
        console.log(error);
        messageApi.open({
          type: 'error',
          content: error?.response?.data?.message || error?.message || GENERIC_ERROR_MESSAGE,
          duration: 5,
        });
      });
  };

  /**
   * This function is called when the demo request is rejected by a host
   *
   * @return {void}
   */

  const handleReject = () => {
    setEnabled(false);
    let body = {
      status: 'UNDECIDED',
    };
    const response = userRejectDemoRequest(body, props?.demoDetail?.id);
    response
      .then(function (response) {
        if (response.status === 200) {
          userDemos();
          userAcceptedDemos();
          setSelectedDateTime('');
          setSelectedMin(false);
          setSelectedHour(false);
          setNewTiming([]);
          setEnabled(true);
        }
      })
      .catch(function (error) {
        userDemos();
        userAcceptedDemos();
        setSelectedDateTime('');
        setEnabled(true);
        messageApi.open({
          type: 'error',
          content: error?.response?.data?.message || error?.message || GENERIC_ERROR_MESSAGE,
          duration: 5,
        });
      });
  };

  //shows hours whie scheduling call
  const morning = [5, 6, 7, 8, 9, 10, 11];
  const afternoon = [12, 13, 14, 15, 16];
  const evening = [17, 18, 19, 20];
  const includeString = ['morning', 'afternoon', 'evening'];
  const selectedTime = [];

  /**
   * This function is used to check whether to keep the schedule button disable or not
   *
   * @return {boolean}
   */
  const checkScheduleDisable = () => {
    const hourAndMinNotSelected = !selectedHour && !selectedMin;
    const dateOrTimeNotSelected = !selectedDateTime || hourAndMinNotSelected;
    if (
      props?.demoDetail?.date_time_range?.length > 0 &&
      (!props?.demoDetail?.date_time_range[0].time_options ||
        !props?.demoDetail?.date_time_range[0].time_options?.length)
    ) {
      return (
        dateOrTimeNotSelected ||
        !enabled ||
        (props?.demoDetail?.product?.id ? '' : !productDropdownSelect)
      );
    } else {
      return (
        !selectedDateTime ||
        !enabled ||
        (props?.demoDetail?.product?.id ? '' : !productDropdownSelect)
      );
    }
  };

  /**
   * This function is used to get the date options in the dropdown selected by the guest
   *
   * @return {Array} Array of date options
   */

  const getDateOptions = () => {
    let date_time_options = [];
    if (
      props?.demoDetail?.date_time_range?.length > 0 &&
      props?.demoDetail?.date_time_range[0].time_options?.length > 0
    ) {
      date_time_options = extractTimeOptions(
        props?.demoDetail?.date_time_range
      );
      return date_time_options.map((option, index) => {
        return (
          <option key={index} value={option}>
            <span>{option} </span>
            <span>
              ( UTC{moment.tz(moment.tz.guess()).format('Z')}{' '}
              {convertTimeZoneFormat(moment.tz.guess())} ){' '}
            </span>
          </option>
        );
      });
    } else {
      return getDateRange();
    }
  };

  /**
   * This function is used to get the date options based on the previous flow of Morning, Afternoon and Evening
   *
   * @return {Array} Array of date options
   */

  const getDateRange = () => {
    return props.demoDetail.date_time_range.map((meet, index) => {
      let d = moment(meet.date).format('MM-DD-YYYY');
      meet.time_range.map((day) => {
        if (day === 'MORNING') {
          d = d + ', Morn';
        } else if (day === 'EVENING') {
          d = d + ', Eve';
        } else if (day === 'AFTERNOON') {
          d = d + ', Aft';
        }
      });
      if (calculateDifferenceToday(meet.date, 'days') >= 0) {
        return (
          <option key={index} value={meet.date}>
            {d}
          </option>
        );
      }
    });
  };

  /**
   * This function is used to get the time range for the selected date based on the previous flow of Morning, Afternoon and Evening
   *
   * @return {Array} Array of time options
   */

  const getTimeRange = (selectedDate) => {
    const myDate = props?.demoDetail?.date_time_range?.filter(
      (x) => x.date === selectedDate
    );
    let tempTime = myDate[0].time_range;
    tempTime.map((x) => selectedTime.push(x.toLowerCase()));
    let excludetime = includeString.filter((x) => selectedTime.includes(x));
    let includeTime = [];
    excludetime.map((x) => {
      includeTime = includeTime.concat(eval(x));
    });
    setNewTiming(getTimeArray(includeTime));
  };

  /**
   * This function is used to change the time range for the selected date based on the previous flow of Morning, Afternoon and Evening
   *
   * @return {Array} Array of time options
   */

  const handleChange = (e) => {
    setSelectedDateTime(e);
    if (
      props?.demoDetail?.date_time_range?.length > 0 &&
      e &&
      (!props.demoDetail.date_time_range[0].time_options ||
        !props.demoDetail.date_time_range[0].time_options?.length)
    ) {
      getTimeRange(e);
    }
  };

  const handleChangeHour = (e) => {
    setSelectedHour(true);
  };
  const handleChangeMin = (e) => {
    setSelectedMin(true);
  };

  /**
   * This function is used to get the text of the reject button
   *
   * @return {String} text of the reject button
   */

  const getRejectButtonText = () => {
    if (enabled) {
      return 'Reject';
    }
    return 'Busy...';
  };

  /**
   * This function is called when the host has scheduled a meeting at the soonest time available
   *
   * @return {void}
   */

  const acceptAndJoin = () => {
    setEnabled(false);
    const newUTCDate = new Date().toISOString().split('T');
    const newUTCTime = newUTCDate[1].split('.');
    const body = {
      schedule: {
        scheduled_date: newUTCDate[0],
        scheduled_time: newUTCTime[0],
      },
      product_id:
        props.demoDetail.product !== null
          ? props.demoDetail.product.id
          : productDropdownSelect,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    const response = userAcceptDemoRequest(body, props.demoDetail.id);
    response
      .then(function (resp) {
        if (resp && resp.status === 200) {
          navigate(`/demo/waiting-room/${props.demoDetail.id}`);
          setEnabled(true);
        }
      })
      .catch(function (error) {
        messageApi.open({
          type: 'error',
          content: error?.response?.data?.message || error?.message || GENERIC_ERROR_MESSAGE,
          duration: 5,
        });
        setEnabled(true);
        console.log(error);
        // throw error;
      });
  };

  const selectProductFromDropdown = (e) => {
    const selectedId = e.target.value;
    setProductDropdownSelect(selectedId);
    // Find the product with the selected ID
    const selectedProduct = props.demoDetail.store_products.find(
      (res) => res.id === selectedId
    );
    // Set the name of the selected product
    if (selectedProduct) {
      setProductDropdownSelected(selectedProduct?.name);
    } else {
      setProductDropdownSelected('');
    }
  };

  /**
   * This function is used to get the text of the accept and join button
   *
   * @return {String} text of the accept and join button
   */

  const getAcceptAndJoinText = () => {
    if (enabled) {
      return 'Accept and Join';
    }
    return 'Joining....';
  };

  return (
    <>
    {contextHolder}
    <div className={`request-component ${props.customClass}`}>
      <div className="guest-detail-wrapper">
        <div className="guest-name">
          <strong>
            {props?.demoDetail?.members?.guest_detail?.full_name?.toUpperCase()}
          </strong>
        </div>
        <div className="date_wrapper">
          Time Waiting:{props.index}{' '}
          {getTimeDifferencetimezone(props.demoDetail.created_at)}
        </div>
        <div className="product-name-wrapper mb-1">
          {props?.demoDetail?.product && (
            <p> Demo for: {props?.demoDetail?.product?.name}</p>
          )}
          {user.role === 3 && props.demoDetail.product === null && (
            <div className="product-list my-2">
              <p>
                Demo for:{' '}
                {props.demoDetail.product
                  ? props?.demoDetail?.product?.name
                  : productDropdownSelected}
              </p>
              <select
                className="form-select "
                required
                defaultValue={'select'}
                onChange={(e) => selectProductFromDropdown(e)}
              >
                <option disabled value="select">
                  Select Demo Product
                </option>
                {props?.demoDetail?.store_products?.map((res) => {
                  return (
                    <option key={res.id} value={res.id}>
                      {res.name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          <p>Store: {props.demoDetail.store.name}</p>
        </div>
        <div className="note-display-wrapper">{props.demoDetail.notes}</div>
        {user.role === 3 && !props?.storesLoading ? (
          <>
            {checkDemoDisabled() && !props?.storesLoading ? (
              <>
                <div className="text-center">
                  <p className="text-danger">
                    Please connect with this store's dots app to accept the
                    demo. Also make sure you have a default payout method
                    configured and compliance information added on your dot's account
                  </p>
                  <button
                    className="text-white btn border-1 border-danger bg-danger"
                    onClick={() => {
                      navigate('/user/settings');
                    }}
                  >
                    Click Here
                  </button>{' '}
                </div>
              </>
            ) : (
              <div>
                {props.demoDetail.soonest_at !== null && !props?.storesLoading ? (
                  <div className="time-detail-wrapper">
                    <button
                      disabled={
                        (props?.demoDetail?.product?.id
                          ? ''
                          : !productDropdownSelect) || !enabled
                      }
                      onClick={acceptAndJoin}
                      className="btn btn-secondary btn-sm"
                    >
                      {getAcceptAndJoinText()}
                    </button>
                  </div>
                ) : (
                  !props?.storesLoading && <div className="time-detail-wrapper">
                    <Formik
                      initialValues={{
                        demo_scheduled_date: '',
                        demo_scheduled_time: '',
                        minute: '00',
                      }}
                      onSubmit={handleSubmit}
                    >
                      {({ touched, errors, setFieldValue, values }) => (
                        <Form>
                          <Field
                            as={Select}
                            name="demo_scheduled_date"
                            value={selectedDateTime}
                            onChange={(e) => {
                              setFieldValue('demo_scheduled_date', e);
                              handleChange(e);
                            }}
                            className={`${
                              touched.demo_scheduled_date &&
                              errors.demo_scheduled_date
                                ? 'is-invalid'
                                : ''
                            }`}
                          >
                            <option value="">See Dates</option>
                            {getDateOptions()}
                          </Field>

                          {selectedDateTime !== '' &&
                          !(
                            props.demoDetail.date_time_range[0].time_options &&
                            props.demoDetail.date_time_range[0].time_options
                              .length !== 0
                          ) ? (
                            <div className="custom-time-wrapper">
                              <div className="row">
                                <div className="col col-6">
                                  <label>
                                    <p className="small-label">Hour</p>
                                  </label>
                                  <Field
                                    as="select"
                                    name="hour"
                                    onChange={(e) => {
                                      setFieldValue('hour', e.target.value);
                                      handleChangeHour(e);
                                    }}
                                    className={`form-select form-select-sm ${
                                      touched.hour && errors.hour
                                        ? 'is-invalid'
                                        : ''
                                    }`}
                                  >
                                    <option value="">00</option>
                                    {newTiming.map((timing, index) => (
                                      <option key={index} value={timing.date}>
                                        {timing.showing} {timing.meridian}
                                      </option>
                                    ))}
                                  </Field>
                                </div>
                                <div className="col col-6">
                                  <label>
                                    <p className="small-label">Minute</p>
                                  </label>
                                  <Field
                                    as="select"
                                    name="minute"
                                    default={'00'}
                                    onChange={(e) => {
                                      setFieldValue('minute', e.target.value);
                                      handleChangeMin(e);
                                    }}
                                    className={`form-select form-select-sm ${
                                      touched.minute && errors.minute
                                        ? 'is-invalid'
                                        : ''
                                    }`}
                                  >
                                    <option value={'00'}>00</option>
                                    <option value={'15'}>15</option>
                                    <option value={'30'}>30</option>
                                    <option value={'45'}>45</option>
                                  </Field>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          <button
                            disabled={checkScheduleDisable()}
                            data-testId={TEST_IDS.SCHEDULE_BTN}
                            className="btn btn-secondary btn-sm"
                            onClick={() => {
                              setFieldValue(
                                'demo_scheduled_time',
                                `${values.hour}:${values.minute}`
                              );
                            }}
                            type="submit"
                          >
                            Schedule
                          </button>
                        </Form>
                      )}
                    </Formik>
                    <button
                      data-testId={TEST_IDS.REJECT_BTN}
                      onClick={handleReject}
                      disabled={!enabled}
                      className="btn btn-demo-reject btn-secondary btn-sm"
                    >
                      {getRejectButtonText()}
                    </button>
                  </div>
                )}
              </div>
            )}
          </>
        ) : (<></>)}
      </div>
    </div>
    </>
  );
};

export default React.memo(MeetingReqView);
