import PurebrandLoader from "components/Loader/PurebrandLoader";
import CustomModal from "components/Modal/CustomModal";
import DataContext from "context/DataContext";
import React, { useContext, useState } from "react";

const ViewAdminList = ({ adminList }) => {
  const { reAssignNewRole, updateAssignedRole, getAssignedRole } =
    useContext(DataContext);
  const [show, setShow] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [failed, setFailed] = useState(false);
  const [editRole, setEditRole] = useState(false);
  const [roleId, setRoleId] = useState();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };

  const reinviteHandle = async () => {
    setLoading(true);
    try {
      const res = await reAssignNewRole(adminList.id);
      if (res.status === 200) {
        setShow(true);
        setLoading(false);
      }
    } catch (error) {
      setShow(true);
      setLoading(false);
      setFailed(true);
    }
  };

  const updateAssignedRoleHandle = async () => {
    setLoading(true);
    try {
      const res = await updateAssignedRole(roleId, adminList.id);
      if (res.status === 200) {
        setShowToast(true);
        setLoading(false);
        setEditRole(false);
        try {
          await getAssignedRole(`?order=true`);
        } catch (err) {
          setLoading(true);
        }
      }
    } catch (error) {
      setShowToast(true);
      setFailed(true);
      setLoading(false);
    }
  };

  if (loading) {
    return <PurebrandLoader />;
  }

  return (
    <>
      <CustomModal
        showStatus={show}
        clickStatus={handleClose}
        buttonMessage="Close"
        closeStatus={handleClose}
        title={!failed ? "Success" : "Failed"}
        message={
          !failed ? "Re-Invitation mail has been sent." : "Reinvitation Failed"
        }
        secondButton={false}
        secondMessage="none"
      />

      <CustomModal
        showStatus={showToast}
        clickStatus={handleCloseToast}
        buttonMessage="Close"
        closeStatus={handleCloseToast}
        title={!failed ? "Success" : <p className="text-danger h4"> Failed</p>}
        message={!failed ? "Admin Role Updated" : "Admin Role Updating Faiiled"}
        secondButton={false}
        secondMessage="none"
      />

      <tr>
        <td>
          {adminList?.first_name} {adminList?.last_name}
        </td>
        <td>{adminList?.email}</td>
        <td>
          {!editRole ? (
            <>
              {" "}
              {adminList.role === 3 && <>Sales Person</>}
              {adminList.role === 4 && <>Owner</>}
              {adminList.role === 6 && <>Edit Admin</>}
              {adminList.role === 5 && <>View Only</>}
            </>
          ) : (
            <div>
              <select
                className="form-select"
                defaultValue={adminList.role}
                onChange={(e) => {
                  setRoleId(e.target.value);
                }}
              >
                <option disabled> Select Role </option>
                <option value="4">Owner</option>
                <option value="6">Edit Admin</option>
                <option value="5">View Only</option>
              </select>
            </div>
          )}
        </td>
        <td>
          {
            adminList?.role !== 3 && (
              <>
                <button
                  className="btn bg-danger text-white m-1"
                  onClick={() => {
                    setEditRole(!editRole);
                  }}
                >
                  {editRole ? "Cancel" : "Edit"}
                </button>
              </>
            )
          }

          {/* //delete button */}
          {/* <button
            className="btn bg-danger text-white m-1"
            onClick={() => {
              setEditRole(!editRole);
            }}
          >
            Delete
          </button> */}

          {!editRole ? (
            <button
              className="btn bg-primary text-white m-1"
              hidden={adminList?.has_signed_up === true}
              onClick={reinviteHandle}
            >
              Reinvite
            </button>
          ) : (
            <button
              className="btn bg-primary text-white m-1"
              onClick={updateAssignedRoleHandle}
            >
              Update
            </button>
          )}
        </td>
      </tr>
    </>
  );
};

export default ViewAdminList;
