import React, { useContext, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import "./Form.css";
import Imagecomponent from "./Imagecomponent";
import { Navigate, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AuthContext from "context/AuthContext";
import { Alert } from "react-bootstrap";
import { base64Decode } from "@firebase/util";
import { capitalizeFirst } from "utils/stringFormatter";

// const phoneRegExp =
//   /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
// const passwordRegExp = /^(?=.{7,24}$)(?=\w)(?=.*?[#?!@$%^&*-])(?=.*\d)/;
export const passwordRegExp =
  /^(?=^.{8,24}$)((?=.*[A-Za-z])|(?=.*\d)|(?=.*[@$!%*?&#^-]))(?=^.*[A-Za-z].*$)(?=^.*\d.*$)(?=^.*[@$!%*?&#^-].*$).*$/;
// const zipRegExp = /^\d{5}(?:[-\s]\d{4})?$/;
const LoginSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "Mininum 2 characters")
    .max(15, "Maximum 15 characters")
    .required("Required!"),
  last_name: Yup.string()
    .min(2, "Mininum 2 characters")
    .max(15, "Maximum 15 characters")
    .required("Required!"),
  email: Yup.string()
    .email("Invalid email address format")
    .required("Email is required"),
  password: Yup.string()
    .matches(
      passwordRegExp,
      "Must have 8 characters at least with number and a special character"
    )
    .required("Password is required"),
  confirm_password: Yup.string().when("password", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Both password need to be the same"
    ),
  }),
});

const SignupForm = (props) => {
  const { user, signUpUser, signupErrors } =
    useContext(AuthContext);
  const [password, setPassword] = useState(true);
  const [password2, setPassword2] = useState(true);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  if (user) {
    return <Navigate to="/user/welcome" />;
  } else {
    return (
      <div className="container signup-wrapper">
        <div className="row signup-item-wrap">
          <div className="col-md-6">
            {/* <p
              className="paragraph"
              style={{
                fontSize: "18px",
                textAlign: "left",
                marginTop: "50px",
              }}
            >
              Murphy Door has chosen Purebrand as our trusted review and
              customer hosting partner. Our future customers save money, our
              past customers make money.
            </p> */}
            <p
              className="signup-header"
              style={{
                fontSize: "25px",
                textAlign: "left",
                fontWeight: "bold",
              }}
            >
              Create an account
            </p>
            <p className="paragraph">
              Already have an account?{" "}
              <span onClick={() => navigate("/login")} className="login-link">
                Sign in here
              </span>
            </p>
            <Formik
              initialValues={{
                email: `${
                  queryParams.get("invite")
                    ? base64Decode(queryParams.get("invite"))
                    : ""
                }`,
                password: "",
                confirm_password: "",
                first_name: "",
                last_name: "",
              }}
              onSubmit={(data, { setSubmitting }) => {
                const body = {
                  email: data.email,
                  password: data.password,
                  confirm_password: data.confirm_password,
                  first_name: capitalizeFirst(data?.first_name),
                  last_name: capitalizeFirst(data.last_name),
                  role: 3,
                };

                // TODO: This needs to completed once this shopify api is corrected

                // updateShopifySignup(
                //   `?customer_email=${data.email}&shop_id=10984882235||67808133441`
                // );

                const signupResponse = signUpUser(body);

                signupResponse
                  .then(function (response) {
                    if (response.status === 201) {
                      navigate("/login", {
                        state: {
                          changedPassword: "Check your email for verification",
                          variant: "success",
                          email: data.email,
                        },
                      });
                      setSubmitting(false);
                    }
                  })
                  .catch(function (error) {
                    setSubmitting(false);
                  });
              }}
              validationSchema={LoginSchema}
            >
              {({ touched, errors, values, isSubmitting }) => (
                <Form className="sign-up-wrap">
                  <div className="row">
                    <div className="col-md-12">
                      {signupErrors ? (
                        <Alert className="" key="danger" variant="danger">
                          {signupErrors}
                        </Alert>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <Field
                        type="text"
                        name="first_name"
                        placeholder="First Name"
                        className={`form-control ${
                          touched.first_name && errors.first_name
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="first_name"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="col-md-6 mb-3">
                      <Field
                        type="text"
                        name="last_name"
                        placeholder="Last Name"
                        className={`form-control ${
                          touched.last_name && errors.last_name
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="last_name"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="col-md-12 mb-3">
                      <Field
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        className={`form-control ${
                          touched.email && errors.email ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="email"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="col-md-6 mb-3">
                      <div className="password-enter-wrapper">
                        <Field
                          type={`${password ? "password" : "text"}`}
                          name="password"
                          placeholder="Enter password"
                          className={`form-control ${
                            touched.password && errors.password
                              ? "is-invalid"
                              : ""
                          }`}
                        />{" "}
                        <ErrorMessage
                          component="div"
                          name="password"
                          className="invalid-feedback"
                        />
                        <div
                          className="password-visibility"
                          onClick={() => setPassword(!password)}
                        >
                          {password ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="38"
                              height="38"
                              viewBox="0 0 38 38"
                            >
                              <path d="M2.03381935,11.8192361 C4.16808919,6.31506648 10.3602678,3.58322297 15.8644374,5.71749281 C18.6654288,6.80359152 20.8800819,9.01824467 21.9661807,11.8192361 C22.0112731,11.9355272 22.0112731,12.0644728 21.9661807,12.1807639 C19.8319108,17.6849335 13.6397322,20.416777 8.1355626,18.2825072 C5.3345712,17.1964085 3.11991805,14.9817553 2.03381935,12.1807639 C1.98872688,12.0644728 1.98872688,11.9355272 2.03381935,11.8192361 Z M8.49709046,17.3501459 C13.4256883,19.2612348 18.9628618,16.8680219 20.9616531,12.0000475 C19.9532865,9.54665483 17.9813179,7.6108696 15.5029095,6.64985412 C10.5743117,4.73876518 5.0371382,7.13197809 3.03834692,11.9999525 C4.04671349,14.4533452 6.01868212,16.3891304 8.49709046,17.3501459 Z M12,16 C9.790861,16 8,14.209139 8,12 C8,9.790861 9.790861,8 12,8 C14.209139,8 16,9.790861 16,12 C16,14.209139 14.209139,16 12,16 Z M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 Z" />
                            </svg>
                          ) : (
                            <svg
                              fill="none"
                              height="38"
                              strokeWidth="1.5"
                              viewBox="0 0 38 38"
                              width="38"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3 3L21 21"
                                stroke="currentColor"
                                stroke-linecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10.5 10.6771C10.1888 11.0296 10 11.4928 10 12C10 13.1045 10.8954 14 12 14C12.5072 14 12.9703 13.8112 13.3229 13.5"
                                stroke="currentColor"
                                stroke-linecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M7.36185 7.5611C5.68002 8.73968 4.27894 10.4188 3 12C4.88856 14.991 8.2817 18 12 18C13.5499 18 15.0434 17.4772 16.3949 16.6508"
                                stroke="currentColor"
                                stroke-linecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M12 6C16.0084 6 18.7015 9.1582 21 12C20.6815 12.5043 20.3203 13.0092 19.922 13.5"
                                stroke="currentColor"
                                stroke-linecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 mb-3">
                      <div className="password-enter-wrapper">
                        <Field
                          type={`${password2 ? "password" : "text"}`}
                          name="confirm_password"
                          placeholder="Confirm password"
                          className={`form-control ${
                            touched.confirm_password && errors.confirm_password
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="confirm_password"
                          className="invalid-feedback"
                        />
                        <div
                          className="password-visibility"
                          onClick={() => setPassword2(!password2)}
                        >
                          {password2 ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="38"
                              height="38"
                              viewBox="0 0 38 38"
                            >
                              <path d="M2.03381935,11.8192361 C4.16808919,6.31506648 10.3602678,3.58322297 15.8644374,5.71749281 C18.6654288,6.80359152 20.8800819,9.01824467 21.9661807,11.8192361 C22.0112731,11.9355272 22.0112731,12.0644728 21.9661807,12.1807639 C19.8319108,17.6849335 13.6397322,20.416777 8.1355626,18.2825072 C5.3345712,17.1964085 3.11991805,14.9817553 2.03381935,12.1807639 C1.98872688,12.0644728 1.98872688,11.9355272 2.03381935,11.8192361 Z M8.49709046,17.3501459 C13.4256883,19.2612348 18.9628618,16.8680219 20.9616531,12.0000475 C19.9532865,9.54665483 17.9813179,7.6108696 15.5029095,6.64985412 C10.5743117,4.73876518 5.0371382,7.13197809 3.03834692,11.9999525 C4.04671349,14.4533452 6.01868212,16.3891304 8.49709046,17.3501459 Z M12,16 C9.790861,16 8,14.209139 8,12 C8,9.790861 9.790861,8 12,8 C14.209139,8 16,9.790861 16,12 C16,14.209139 14.209139,16 12,16 Z M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 Z" />
                            </svg>
                          ) : (
                            <svg
                              fill="none"
                              height="38"
                              strokeWidth="1.5"
                              viewBox="0 0 38 38"
                              width="38"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3 3L21 21"
                                stroke="currentColor"
                                stroke-linecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10.5 10.6771C10.1888 11.0296 10 11.4928 10 12C10 13.1045 10.8954 14 12 14C12.5072 14 12.9703 13.8112 13.3229 13.5"
                                stroke="currentColor"
                                stroke-linecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M7.36185 7.5611C5.68002 8.73968 4.27894 10.4188 3 12C4.88856 14.991 8.2817 18 12 18C13.5499 18 15.0434 17.4772 16.3949 16.6508"
                                stroke="currentColor"
                                stroke-linecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M12 6C16.0084 6 18.7015 9.1582 21 12C20.6815 12.5043 20.3203 13.0092 19.922 13.5"
                                stroke="currentColor"
                                stroke-linecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Please wait..." : "Signup"}
                  </button>
                </Form>
              )}
            </Formik>
          </div>

          <div className="col-md-6">
            <Imagecomponent />
          </div>
        </div>
      </div>
    );
  }
};

export default SignupForm;

//validate: (value) => value === pwd || "The passwords do not match"
