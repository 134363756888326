import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Landing from "Pages/landing/Landing";
import Signup from "Pages/Auth/signup/Host/Signup";
import Login from "Pages/Auth/login/Host/Login";
import SignupCompany from "Pages/Auth/signup/Company/SignupCompany";
import LoginCompany from "Pages/Auth/login/Company/LoginCompany";
import Host from "Pages/dashboard/host/Host";
import Welcome from "Pages/dashboard/host/Welcome";
import ApprovalWait from "components/Forms/Multistep/ApprovalWait";
import MyMeetings from "Pages/dashboard/host/Meetings/MyMeetings";
import MyShowrooms from "Pages/dashboard/host/Showrooms/MyShowrooms";
import UserSettings from "Pages/dashboard/host/Settings/UserSettings";
import LoyaltyCard from "components/Forms/MurphyCard/LoyaltyCard";
// import history from "utils/history";
import { createBrowserHistory } from "history";
import ProductAddition from "Pages/dashboard/host/Showrooms/ProductAddition";
import ViewHost from "Pages/dashboard/store/Hosts/ViewHost";
import Recordings from "Pages/dashboard/store/Recordings/Recordings";
import ForgotPassword from "Pages/Auth/Password/ForgotPassword";
import ResetPassword from "Pages/Auth/Password/ResetPassword";
import UserInformation from "Pages/dashboard/Profile/UserInformation";
import ChangePassword from "Pages/Auth/Password/ChangePassword";
// import PushNotification from "../components/Notification/push-notification";
import SignupVerify from "Pages/Auth/signup/SignupVerify";
import VideoMeeting from "../Pages/videoChat/Video-meeting";
import WaitingRoom from "../Pages/videoChat/WaitingRoom";
import Payouts from "Pages/dashboard/host/MurphyCard/Payouts";
import Sidebaar from "layout/Sidebaar";
import Headerss from "layout/Headers";
import PaymentPurebrand from "Pages/dashboard/PureBrand/Payments/PaymentPurebrand";
import RecordMeetings from "../Pages/Recordings/RecordMeetings";
import AdminRoute from "./AdminRoute";
import UserRoute from "./UserRoute";
import NotificationPage from "Pages/Notification/Notification";
import {
  PrivacyPolicyPB,
  TermsAndConditionsPB,
} from "components/Agreements/LegalAgreements";
import AdminHostPage from "Pages/dashboard/PureBrand/Hosts/AdminHostPage";
import Companies from "Pages/dashboard/PureBrand/Companies/Companies";
import ReviewPageAdmin from "Pages/dashboard/PureBrand/Companies/ReviewPageAdmin";
import StoreBills from "Pages/dashboard/store/Billings/StoreBills";
import ContactSupport from "Pages/Support/ContactSupport";
import Checkout from "Pages/Payments/Checkout";
import MeetingStoreView from "Pages/dashboard/store/Payments/MeetingStoreView";
import ShowroomEdit from "Pages/dashboard/host/Showrooms/ShowroomEdit";
import Meetings from "Pages/dashboard/host/Meetings/Meetings";
import NotFound from "Pages/Error/NotFound";
import ProfilePublic from "Pages/PublicUser/ProfilePublic";
import HostProfilePublic from "Pages/PublicUser/HostProfilePublic";
import SoftReview from "Pages/PublicUser/SoftReview";
import SeeReview from "Pages/PublicUser/SeeReview";
import MeetingStatusPublic from "Pages/PublicUser/MeetingStatusPublic";
import QuickSignup from "Pages/PublicUser/QuickSignup";
import FirstTimeChange from "Pages/Auth/Password/FirstTimeChange";
import StoreDemos from "Pages/dashboard/store/Demos/StoreDemos";
import ShopifyDetails from "Pages/dashboard/store/ShopifyStoreDetails/ShopifyDetails";
// import StripeComplete from "Pages/StripeComplete";
import ProductListView from "Pages/dashboard/store/ProductLists/ProductListView";
import AddRole from "Pages/dashboard/store/RolesForm/AddRole";
import AdminOnboard from "Pages/Auth/signup/AdminOnboard";
import Subscription from "Pages/dashboard/store/Subscription/Subscription";
import LiveMeeting from "Pages/PublicUser/LiveMeeting";

const CustomRouter = () => {
  const history = createBrowserHistory();
  return (
    <BrowserRouter history={history}>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route element={<SeeReview />} path="/view" />
        <Route path="/" element={<Headerss />}>
          <Route element={<Landing />} path="/" exact />
          <Route element={<Login />} path="/login" />
          <Route element={<ForgotPassword />} path="/forgot-password" />
          <Route
            element={<ResetPassword />}
            path="/reset-password/:uid/:token/"
          />
          <Route
            element={<AdminOnboard />}
            path="/on-boarding-complete/:uid/:token/"
          />
          <Route
            element={<MeetingStatusPublic />}
            path="/meeting-status-check"
          />
          <Route element={<Signup />} path="/signup" />
          <Route element={<SignupVerify />} path="/verify-account/:uid/" />
          <Route element={<SignupCompany />} path="/store-signup" />
          <Route element={<LoginCompany />} path="/store-login" />
          <Route element={<PrivacyPolicyPB />} path="/privacy-policy" />
          <Route
            element={<TermsAndConditionsPB />}
            path="/terms-and-conditions"
          />
          <Route element={<ContactSupport />} path="/support" />
          <Route element={<ProfilePublic />} path="/demo/:uid/" />
          <Route element={<HostProfilePublic />} path="/host/:uid/" />
          <Route element={<SoftReview />} path="/review" />
          <Route element={<QuickSignup />} path="/join" />
        </Route>
        <Route
          path="/user"
          element={
            <PrivateRoute>
              <Sidebaar />
            </PrivateRoute>
          }
        >
          <Route
            element={
              <AdminRoute>
                <RecordMeetings />
              </AdminRoute>
            }
            path="/user/recordings"
          />
          <Route
            element={
              <AdminRoute>
                <PaymentPurebrand />
              </AdminRoute>
            }
            path="/user/payments"
          />
          <Route
            element={
              <AdminRoute>
                <AdminHostPage />
              </AdminRoute>
            }
            path="/user/store-hosts"
          />
          <Route
            element={
              <AdminRoute>
                <ReviewPageAdmin />
              </AdminRoute>
            }
            path="/user/review"
          />
          <Route
            element={
              <AdminRoute>
                <StoreBills />
              </AdminRoute>
            }
            path="/user/accounting"
          />

          {/* TODO: Uncomment this once the investigation of soft reviews and code snippets feature is completed */}
          
          {/* <Route
            element={
              <AdminRoute>
                <SoftReviewAdmin />
              </AdminRoute>
            }
            path="/user/soft-reviews"
          /> */}
          {/* <Route
            element={
              <AdminRoute>
                <CodeSnippetAdmin />
              </AdminRoute>
            }
            path="/user/codeSnippet"
          /> */}
          <Route
            element={
              <AdminRoute>
                <ProductListView />
              </AdminRoute>
            }
            path="/user/productsList"
          />
          <Route
            element={
              <AdminRoute>
                <ShopifyDetails />
              </AdminRoute>
            }
            path="/user/shopifyDetails"
          />
          <Route
            element={
              <AdminRoute>
                <Companies />
              </AdminRoute>
            }
            path="/user/companies"
          />
          <Route
            element={
              <AdminRoute>
                <ViewHost />
              </AdminRoute>
            }
            path="/user/host"
          />
          {/* <Route
            element={
              <AdminRoute>
                <MeetingStoreView />
              </AdminRoute>
            }
            path="/user/payment-store"
          /> */}
          <Route
            element={
              <AdminRoute>
                <Checkout />
              </AdminRoute>
            }
            path="/user/checkout"
          />
          <Route
            element={
              <AdminRoute>
                <StoreDemos />
              </AdminRoute>
            }
            path="/user/store-meetings"
          />
          <Route element={<Welcome />} path="/user/welcome" />
          <Route element={<Host />} path="/user/completeprofile" />
          <Route
            element={
              <UserRoute>
                <ApprovalWait />
              </UserRoute>
            }
            path="/user/meeting-status"
          />
          <Route
            element={
              <UserRoute>
                <Meetings />
              </UserRoute>
            }
            path="/user/meetings"
          />
          <Route
            element={
              <UserRoute>
                <MyShowrooms />
              </UserRoute>
            }
            path="/user/showrooms"
          />

          <Route element={<AddRole />} path={`/user/addRole`} />
          <Route element={<UserSettings />} path="/user/settings" />
          <Route
            element={
              <AdminRoute>
                <Subscription />
              </AdminRoute>
            }
            path="/user/subscription"
          />
          <Route element={<LoyaltyCard />} path="/user/apply-loyalty" />
          <Route
            element={
              <UserRoute>
                <Payouts />
              </UserRoute>
            }
            path="/user/my-accounting"
          />
          <Route element={<ProductAddition />} path="/user/addproduct" />
          <Route element={<ShowroomEdit />} path="/user/editproduct/:uid/" />
          <Route element={<NotificationPage />} path="/user/notifications" />
          <Route element={<UserInformation />} path="/user/profile" />
          <Route element={<ChangePassword />} path="/user/change-password" />
          <Route element={<FirstTimeChange />} path="/user/set-password" />
        </Route>
        <Route path="/demo" element={<Headerss />}>
          {/* <Route element={<WaitingRoom />} path="/demo/waiting-room" /> */}
          <Route element={<WaitingRoom />} path="/demo/waiting-room/:demoId" />
          <Route element={<VideoMeeting />} exact path="/demo/room/:userName" />
        </Route>
        <Route element={<LiveMeeting />} path="/live-meeting/:demoId" />
        {/* {user && loggedIn && (
          <>
            <Route element={<StripeComplete />} path={`/stripe-complete/*`} />
          </>
        )} */}
      </Routes>
    </BrowserRouter>
  );
};

export default CustomRouter;
