import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import usePreviewPublisher from '../../hooks/usePreviewPublisher';
import { DEVICE_ACCESS_STATUS } from '../../components/VideoStream/constants';
import AuthContext from '../../context/AuthContext';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DemoContext from 'context/DemoContext';
import CustomModal from 'components/Modal/CustomModal';
import DemoMessageOut from 'components/Demos-Message/MessageOutOfCall';
import { InfoCircleFill } from 'react-bootstrap-icons';
import { message } from 'antd';
import { GENERIC_ERROR_MESSAGE } from 'utils/constants';
import VideoContext from 'context/VideoContext';
import PurebrandLoader from 'components/Loader/PurebrandLoader';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';

/**
 * This component is used to render the waiting room for a host or a guest for the demo preview.
 *
 * @param {void}
 * @return {ReactComponent} It returns a React component for rendering the waiting room for a host or a guest for the demo preview.
 */
export default function WaitingRoom() {
  const navigate = useNavigate();
  const location = useLocation();
  const { demoId } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const { user, setUser, loggedIn } = useContext(AuthContext);
  const {
    setDataForMeeting,
    setHostPresence,
    publicDemoStatus,
    setGuestPresence,
    authorizeHost,
  } = useContext(DemoContext);
  const { getVideoCredentials, setDemoId, setVideoCredentials } =
    useContext(VideoContext);
  const waitingRoomVideoContainer = useRef();
  const [userName, setUserName] = useState('');
  const [isUserNameInvalid, setIsUserNameInvalid] = useState(false);
  const [localAudio, setLocalAudio] = useState(true);
  const [localVideo, setLocalVideo] = useState(true);
  const [sendMessageBox, setSendMessageBox] = useState(false);
  const [cameraMicrophonePermission, setCameraMicrophonePermission] =
    useState(false);
  const [eventDetail, setEventDetail] = useState({});
  const [authorizationError, setAuthorizationError] = useState(false);
  const [authorizationErrorMessage, setAuthorizationErrorMessage] =
    useState('Invalid Request');
  const [authorizationLoading, setAuthorizationLoading] = useState(true);
  const [joinLoading, setJoinLoading] = useState(false);
  const {
    createPreview,
    destroyPreview,
    previewPublisher,
    accessAllowed,
    deviceInfo,
  } = usePreviewPublisher();

  const appID = process.env.REACT_APP_ZEGO_CLOUD_APP_ID;
  const serverSecret = process.env.REACT_APP_ZEGO_CLOUD_SERVER_SECRET;
  

  const handleSendMessageClose = () => {
    setSendMessageBox(false);
  };

  useEffect(() => {
    async function requestPermissions() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        setCameraMicrophonePermission(true);
        // Stop all tracks after permission check
        stream.getTracks().forEach(track => track.stop());
      } catch (error) {
        console.error("Permission denied or error: ", error);
        setCameraMicrophonePermission(false);
      }
    }
    // Request permissions when the component mounts
    requestPermissions();
  }, []);

  const redirectHttps = React.useCallback(() => {
    const url = window.location.href;
    if (
      url.toString().indexOf('http://') === 0 &&
      url.toString().indexOf('http://localhost') !== 0
    ) {
      window.location.href = window.location.href
        .toString()
        .replace('http://', 'https://');
    } else {
      return;
    }
  }, []);

  const getName = () => {
    const res = publicDemoStatus(demoId);
    res
      .then((response) => {
        setEventDetail(response?.data?.data);
        if (user && loggedIn) {
          if (response?.data?.data?.members?.host_detail?.first_name) {
            setUserName(
              `${response.data.data.members.host_detail.first_name} ${response.data.data.members.host_detail.last_name}`
            );
          } else {
            setUserName(`${user?.firstName} ${user?.lastName}`);
          }
        } else {
          setUserName(response?.data?.data?.members?.guest_detail?.full_name);
        }
      })
      .catch((err) => {
        setUserName('');
      });
  };

  /**
   * This function is used to check whether a host is an authorized host or not and manage the states accordingly.
   *
   * @return {void}
   */

  const authorizeHosts = async () => {
    let data;
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('fallback') === 'true') {
      data = {
        fallback: true,
        demoId,
      };
    } else {
      data = {
        demoId,
      };
    }
    try {
      await authorizeHost(data);
      setAuthorizationError(false);
    } catch (err) {
      setAuthorizationErrorMessage(
        err?.response?.data?.message || 'Invalid Request'
      );
      setAuthorizationError(true);
    } finally {
      setAuthorizationLoading(false);
    }
  };

  useEffect(() => {
    getName();
    if (user && loggedIn) {
      authorizeHosts();
    } else {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams.get('fallback') === 'true') {
        navigate(`/login/?returnUrl=${location.pathname}?fallback=true`);
      }
      setAuthorizationLoading(false);
    }
  }, []);

  /**
   * This function is called when a host joins the meeting room from the waiting room and handles the states
   *
   * @param {void}
   * @return {void}
   */

  const handleJoinClick = async () => {
    if (validateForm()) {
      localStorage.setItem('username', userName);
      localStorage.setItem('demoId', eventDetail?.id);
      setJoinLoading(true);
      if (user && loggedIn) {
        const hostId=eventDetail?.members?.host_detail?.id;
        const hostName = eventDetail?.members?.host_detail?.first_name;
        const ExpirationTime = 3600;
        const token = ZegoUIKitPrebuilt.generateKitTokenForTest(
          parseInt(appID),
          serverSecret,
          demoId,
          hostId,
          hostName,
          ExpirationTime
      );
        setDemoId(eventDetail?.id);
        if (eventDetail?.status !== 'STARTED') {
          let response;
          const queryParams = new  URLSearchParams(window.location.search);
          localStorage.setItem('ZEGO_TOKEN', token);
          if (queryParams.get('fallback') === 'true') {
            response = getVideoCredentials(eventDetail?.id, {
              meeting: true,
              fallback: true,
              token,
            });
          } else {
            response = getVideoCredentials(eventDetail?.id, {
              meeting: true,
              token,
            });
          }

          response
            .then(() => {
              const data = {
                name: hostName,
                id: hostId,
                sessionDetail: eventDetail,
                videoState: localVideo,
                audioState: localAudio,
              };
              setDataForMeeting(data);
              navigate(`/demo/room/${userName}`, {
                state: data,
              });
            })
            .catch((e) => {
              messageApi.open({
                type: 'error',
                content: GENERIC_ERROR_MESSAGE,
              });
              setAuthorizationErrorMessage(
                e?.response?.data?.message || 'Invalid Request'
              );
              setAuthorizationError(true);
            })
            .finally(() => {
              setJoinLoading(false);
            });
        } else {
        const data = {
          sessionId: eventDetail?.id,
          // sessionId: eventDetail?.meeting_details.session_id,
          token,
        };
          localStorage.setItem('ZEGO_TOKEN', token);
          setVideoCredentials(data);
          const res = setHostPresence(eventDetail?.id, {
            status: true,
          });

          res
            .then(() => {
              const data1 = {
                name: hostName,
                id: hostId,
                sessionDetail: eventDetail,
                videoState: localVideo,
                audioState: localAudio,
              };
              setDataForMeeting(data1);
              navigate(`/demo/room/${userName}`, {
                state: data1,
              });
            })
            .finally(() => {
              setJoinLoading(false);
            });
        }
      } else {
        const guestID=eventDetail?.members?.guest_detail?.id;
        const guestName = eventDetail?.members?.guest_detail?.full_name;
        const token = ZegoUIKitPrebuilt.generateKitTokenForTest(
          parseInt(appID),
          serverSecret,
          eventDetail?.id,
          // eventDetail?.meeting_details?.session_id,
          guestID,
          guestName
      );
        setDemoId(eventDetail?.id);
        const res = setGuestPresence(eventDetail?.id, {
          status: true,
        });
        res
          .then(() => {
            const data = {
              sessionId: eventDetail?.meeting_details?.session_id,
              token: eventDetail?.meeting_details?.guest_token,
            };
            localStorage.setItem('ZEGO_TOKEN', token);
            setVideoCredentials(data);

            const data1 = {
              name:guestName,
              id: guestID,
              sessionDetail: eventDetail,
              videoState: localVideo,
              audioState: localAudio,
            };
            setDataForMeeting(data1);
            navigate(`/demo/room/${userName}`, {
              state: data1,
            });
          }).catch(err => {
            messageApi.open({
              type: 'error',
              content: err?.response?.data?.message || GENERIC_ERROR_MESSAGE,
            });
          })
          .finally(() => {
            setJoinLoading(false);
          });
      }
    }
  };

  const validateForm = () => {
    if (userName === '') {
      setIsUserNameInvalid(true);
      return false;
    }
    return true;
  };

  const onChangeParticipantName = (e) => {
    const userName = e.target.value;
    if (userName === '' || userName.trim() === '') {
      // Space detected
      setUserName('');
      return;
    }
    setIsUserNameInvalid(false);
    setUserName(userName);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleJoinClick();
    }
  };

  useEffectOnce(() => {
    setUser({
      ...user,
      videoEffects: {
        backgroundBlur: false,
        virtualBackground: false,
      },
      defaultSettings: {
        publishAudio: localAudio,
        publishVideo: localVideo,
        audioSource: undefined,
        videoSource: undefined,
        audioOutput: undefined,
      },
    });
  }, []);

  useEffect(() => {
    redirectHttps();
  }, [redirectHttps]);

  useEffect(() => {
    if (userName !== user?.userName) {
      setUser({ ...user, userName: userName });
    }
  }, [userName, user, setUser]);

  useEffect(() => {
    if (previewPublisher) {
      if (localAudio && !previewPublisher.isAudioEnabled()) {
        previewPublisher.enableAudio();
      } else if (!localAudio && previewPublisher.isAudioEnabled()) {
        previewPublisher.disableAudio();
      }
    }
  }, [localAudio, previewPublisher]);

  useEffect(() => {
    if (previewPublisher) {
      if (localVideo && !previewPublisher.isVideoEnabled()) {
        previewPublisher.enableVideo();
      } else if (!localVideo && previewPublisher.isVideoEnabled()) {
        previewPublisher.disableVideo();
      }
    }
  }, [localVideo, previewPublisher]);

  useEffect(() => {
    if (waitingRoomVideoContainer.current) {
      createPreview(waitingRoomVideoContainer.current);
    }

    return () => {
      destroyPreview();
    };
  }, [createPreview, destroyPreview, authorizationLoading]);

  if (authorizationLoading) {
    return (
      <>
        <PurebrandLoader />
      </>
    );
  }

  if (authorizationError) {
    return <h2>{authorizationErrorMessage}</h2>;
  }

  return (
    <>
      {contextHolder}
      <div className="container waiting-room-wrapper container">
        <div className="row waiting-contents">
          <div className="waiting-video-wrap d-flex flex-column col-md-8 col-sm-12">
            <div
              className="d-flex justify-content-center"
              id="waiting-room-video-container"
              ref={waitingRoomVideoContainer}
            ></div>
            {/* <div className="mt-4 waiting-video-controller-wrap">
              <AudioSettings
                hasAudio={localAudio}
                onAudioChange={handleAudioChange}
              />
              <VideoSettings
                hasVideo={localVideo}
                onVideoChange={handleVideoChange}
              />
            </div> */}
            {!cameraMicrophonePermission && (
              <div className="text-container-wrap ">
                <div className="point-text-wrap">
                  Before you Join the Call, <br />
                  Make Sure You have turned on your Camera and Microphone
                  permission.
                </div>
              </div>
            )}
            {user && loggedIn && (
              <>
                <div className="text-start pt-5">
                  <InfoCircleFill
                    className="text-info"
                    style={{ fontSize: '30px', paddingRight: '10px' }}
                  />
                  To be eligible for your commission regardless of if the
                  customer joins, we ask you to please wait on the call for at
                  least 10 minutes to give the guest adequate time to join
                </div>
              </>
            )}
          </div>
          <form
            noValidate
            className="col-md-4 col-sm-12 details-content-wrapper"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="d-flex flex-column gap-2 details-wrap">
              <h3>What's your name?</h3>
              <input
                id="publisher-name"
                name="name"
                className="form-control mb-2"
                required
                placeholder="Your name"
                autoComplete="Name"
                defaultValue={userName}
                onChange={onChangeParticipantName}
                onKeyDown={onKeyDown}
                disabled
              />
              <OverlayTrigger
                key={'top'}
                placement={'top'}
                overlay={
                  <Tooltip id={`tooltip-${'top'}`}>
                    The meeting will be recorded.
                  </Tooltip>
                }
              >
                <button
                  color="primary"
                  onClick={handleJoinClick}
                  disabled={
                    !cameraMicrophonePermission ||
                    joinLoading ||
                    authorizationLoading
                  }
                  className="btn btn-primary btn-block"
                >
                  Join Call
                </button>
              </OverlayTrigger>
              <div
                className="btn btn-primary"
                style={{ borderRadius: '10px' }}
                onClick={() => {
                  setSendMessageBox(true);
                }}
              >
                Want to send your {loggedIn ? 'Guest' : 'Host'} a Message
              </div>
              <CustomModal
                showStatus={sendMessageBox}
                clickStatus={handleSendMessageClose}
                buttonMessage="Close"
                closeStatus={handleSendMessageClose}
                title={`Send your ${loggedIn ? 'Guest' : 'Host'} a Message`}
                message={
                  <>
                    <div className="message-textarea">
                      <DemoMessageOut />
                    </div>
                  </>
                }
                secondButton={false}
                secondMessage="none"
              />
              {loggedIn ? (
                <div className="text-container-wrap two_wrap_demo">
                  <div className="points-content-wrap d-flex">
                    When you meet the guest, make sure you
                  </div>
                  <div className="points-content-wrap d-flex">
                    <div className="numbering">1</div>
                    <div className="point-text-wrap">Introduce yourself</div>
                  </div>
                  <div className="points-content-wrap d-flex">
                    <div className="numbering">2</div>
                    <div className="point-text-wrap">Show your product</div>
                  </div>
                  <div className="points-content-wrap d-flex">
                    <div className="numbering">3</div>
                    <div className="point-text-wrap">
                      Tell them about installation or delivery
                    </div>
                  </div>
                  <div className="points-content-wrap d-flex">
                    <div className="numbering">4</div>
                    <div className="point-text-wrap">Be respectful</div>
                  </div>
                </div>
              ) : (
                <div className="text-container-wrap two_wrap_demo">
                  <div className="points-content-wrap d-flex">
                    When you meet the host, make sure you
                  </div>
                  <div className="points-content-wrap d-flex">
                    <div className="numbering">1</div>
                    <div className="point-text-wrap">Introduce yourself</div>
                  </div>
                  <div className="points-content-wrap d-flex">
                    <div className="numbering">2</div>
                    <div className="point-text-wrap">
                      Ask about their experience
                    </div>
                  </div>
                  <div className="points-content-wrap d-flex">
                    <div className="numbering">3</div>
                    <div className="point-text-wrap">Be respectful</div>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      {accessAllowed !== DEVICE_ACCESS_STATUS.ACCEPTED && (
        <span>Device accepted</span>
      )}
    </>
  );
}
