import { Form, Input, Select, Checkbox, Button, message } from 'antd';
import PurebrandLoader from 'components/Loader/PurebrandLoader';
import AuthContext from 'context/AuthContext';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  authorizeLiveMeeting,
  getAllStoreCategories,
  guestJoiningLiveDemo,
} from 'Services/demoService';
import { Purebrand } from 'assets/images';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './LiveMeeting.css';
import { Container, Navbar } from 'react-bootstrap';
import { GENERIC_ERROR_MESSAGE } from 'utils/constants';
import {
  CHECKBOX_TEXT,
  GUEST_FORM_LABELS,
  GUEST_FORM_PLACEHOLDERS,
  GUEST_FORM_VALIDATION_ERRORS,
  LIVE_MEETING_EXPIRY_TEXT,
} from './constants';

/**
 * This component renders the live meeting form for guest users to join a demo session
 * with a host. It handles guest authentication, form submission, and navigation to
 * the waiting room.
 *
 * @return {ReactNode} A React component that renders either a loading state, error message,
 * guest registration form, or invalid meeting message based on the current state
 */
const LiveMeeting = () => {
  const { user, loggedIn } = useContext(AuthContext);
  const { demoId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [host, setHost] = useState(null);
  const [guestDetail, setGuestDetail] = useState(null);
  const [storeCategories, setStoreCategories] = useState([]);
  const [storeIdentifier, setStoreIdentifier] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [joinLoading, setJoinLoading] = useState(false);
  const [error, setError] = useState(null);

  const receiveTexts = Form.useWatch(['guest', 'message_opt_in'], form);

  /**
   * Authorizes the live meeting session by validating the demo ID and user status.
   * Handles navigation for authenticated users and sets up guest session details.
   *
   * @return {Promise<void>} A promise that resolves when the authorization is complete
   */
  const getAuthorizeLiveMeeting = async () => {
    const payload = { demoId };
    try {
      const res = await authorizeLiveMeeting(payload, user, loggedIn);
      if (user && loggedIn) {
        navigate(`/demo/waiting-room/${res?.data?.data?.id}`);
      } else if (res?.data?.data?.members?.[0]?.guest_detail) {
        navigate(`/demo/waiting-room/${res?.data?.data?.id}`);
      } else {
        setHost(res?.data?.data?.members?.[0]?.host_detail);
        setGuestDetail(res?.data?.data?.members?.[0]?.guest_detail);
        setStoreIdentifier(res?.data?.data?.store?.identifier);
      }
    } catch (e) {
      setError(e?.response?.data?.message || GENERIC_ERROR_MESSAGE);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Fetches all available store categories for the current store identifier
   *
   * @return {Promise<void>} A promise that resolves when the categories are fetched
   */
  const getStoreCategories = async () => {
    try {
      const payload = { store_identifier: storeIdentifier };
      const res = await getAllStoreCategories(payload);
      setStoreCategories(res?.data?.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAuthorizeLiveMeeting();
  }, []);

  useEffect(() => {
    if (storeIdentifier) {
      getStoreCategories();
    }
  }, [storeIdentifier]);

  if (isLoading) {
    return (
      <>
        <PurebrandLoader />
      </>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!guestDetail) {
    /**
     * Handles the form submission for guest registration and navigates to the waiting room
     * upon successful submission
     *
     * @param {Object} values - The form values containing guest details
     * @param {Object} values.guest - Guest information including full_name, phone_number, email, and message_opt_in
     * @param {string} values.demoId - The ID of the demo session
     * @param {string} values.product_category - The selected product category ID
     * @return {Promise<void>} A promise that resolves when the form submission is complete
     */
    const handleSubmit = async (values) => {
      try {
        setJoinLoading(true);
        const res = await guestJoiningLiveDemo(values);
        navigate(`/demo/waiting-room/${res?.data?.data?.id}`);
      } catch (e) {
        messageApi.error(e?.response?.data?.message || GENERIC_ERROR_MESSAGE);
      } finally {
        setJoinLoading(false);
      }
    };

    return (
      <div className="live-meeting-form-container">
        {contextHolder}
        <div className="custom-live-header">
          <header>
            <Navbar bg="transparent">
              <Container>
                <Navbar.Brand className="pb-logo-header">
                  <img
                    src={Purebrand}
                    className="Header-img pb-logo-header-public-guest-details"
                    alt="logo"
                    onClick={() => navigate('/')}
                  />{' '}
                </Navbar.Brand>
              </Container>
            </Navbar>
          </header>
        </div>
        <div className="form-container">
          <h1 className="meeting-form-title">
            Enter your information to meet with {host?.first_name}
          </h1>

          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={{
              receiveTexts: false,
              demoId,
            }}
          >
            <Form.Item name="demoId" hidden>
              <Input />
            </Form.Item>
            <Form.Item
              label={GUEST_FORM_LABELS.FULL_NAME}
              name={['guest', 'full_name']}
              rules={[
                {
                  required: true,
                  message: GUEST_FORM_VALIDATION_ERRORS.FULL_NAME,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={GUEST_FORM_LABELS.PHONE_NUMBER}
              name={['guest', 'phone_number']}
              rules={[
                {
                  required: true,
                  message: GUEST_FORM_VALIDATION_ERRORS.PHONE_NUMBER,
                },
              ]}
            >
              <PhoneInput country={'us'} countryCodeEditable={false} />
            </Form.Item>

            <Form.Item
              label={GUEST_FORM_LABELS.EMAIL}
              name={['guest', 'email']}
              rules={[
                { required: true, message: GUEST_FORM_VALIDATION_ERRORS.EMAIL },
                {
                  type: 'email',
                  message: GUEST_FORM_VALIDATION_ERRORS.VALID_EMAIL,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={GUEST_FORM_LABELS.PRODUCT_CATEGORY}
              name="product_category"
              rules={[
                {
                  required: true,
                  message: GUEST_FORM_VALIDATION_ERRORS.PRODUCT_CATEGORY,
                },
              ]}
            >
              <Select placeholder={GUEST_FORM_PLACEHOLDERS.PRODUCT_CATEGORY}>
                {storeCategories.map((category) => (
                  <Select.Option key={category?.id} value={category?.id}>
                    {category?.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name={['guest', 'message_opt_in']}
              valuePropName="checked"
            >
              <Checkbox>{CHECKBOX_TEXT}</Checkbox>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                disabled={!receiveTexts}
                loading={joinLoading}
              >
                Meet {host?.first_name}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  } else {
    return <div>{LIVE_MEETING_EXPIRY_TEXT}</div>;
  }
};

export default LiveMeeting;
